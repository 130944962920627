import { slideDown, slideUp } from 'slide-anim'
import scrollbarWidth from '../utils/scrollbarWidth'

const tabbableSelector =
  'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex^="-"])'

// slide-animの初期値
const SLIDE_DURATION = 400

class GlobalHeader {
  constructor(props) {
    this.elements = {
      ...props,
    }
    this.expandedSearch = false
    this.expandedLogin = false
    this.expandedSpMenu = false
    this.matchMedia = window.matchMedia('screen and (min-width: 1100px)')
    this.observer = null
    this.observeOptions = {
      threshold: [0.0],
      rootMargin: this.isHeaderFirst ? '-60px 0px 0px' : '0px 0px -100%',
    }
    this.observeTarget = this.isHeaderFirst ? this.elements.stickyDetector : this.elements.root
    this.isSmall = false

    // フォーカス管理用
    const spMenuTabbable = this.elements.spMenuContent.querySelectorAll(tabbableSelector)
    const searchTabbable = this.elements.searchContent.querySelectorAll(tabbableSelector)
    const loginTabbable = this.elements.loginContent.querySelectorAll(tabbableSelector)
    this.elements.spMenuLastTabbable = spMenuTabbable[spMenuTabbable.length - 1]
    this.elements.searchLastTabbable = searchTabbable[searchTabbable.length - 1]
    this.elements.loginLastTabbable = loginTabbable[loginTabbable.length - 1]

    // ナビアイテムのインデックス管理用
    this.previousIndex = -1

    // ナビアイテムは最初にホバーしたときにディレイをかけるためのタイマー
    this.navTimer = null

    // SP時にスクロール位置を戻す処理に使うタイマー
    this.spMenuTimer = null
    this.loginMenuTimer = null

    // SP時のスライドアップ/ダウンアニメーション管理用
    this.animating = []

    // SPメニューのポジション管理用
    this.headerPositionFixed = false

    this.init()
  }
  get canUseSticky() {
    const stickyItemList = [
      document.querySelector('.sc21-SecondaryNav.-sticky'),
      document.querySelector('.sc21-CreditCardDetailAnchors'),
    ]
    const existSticky = stickyItemList.some(v => v)
    return existSticky !== true
  }
  get isWideScreen() {
    return this.matchMedia.matches
  }
  get isSmallScreen() {
    return !this.isWideScreen
  }
  get isHeaderFirst() {
    return this.elements.positionDetector.nextElementSibling === this.elements.root
  }
  onEnterNavItem(e, index) {
    if (this.isSmallScreen) {
      return
    }
    clearTimeout(this.navTimer)
    if (this.previousIndex < 0) {
      this.navTimer = setTimeout(() => {
        this.expandNavItem(index)
        this.previousIndex = index
      }, 400)
    } else {
      this.collapseNavItem(this.previousIndex)
      this.expandNavItem(index)
      this.previousIndex = index
    }
  }
  onLeaveNavItem(e, index) {
    if (this.isSmallScreen) {
      return
    }
    this.collapseNavItem(index)
  }
  onFocusNavItem(e, index) {
    if (this.isSmallScreen) {
      return
    }
    if (this.previousIndex > -1) {
      this.collapseNavItem(this.previousIndex)
    }
    this.expandNavItem(index)
    this.previousIndex = index
  }
  onLeaveNavList(e) {
    if (this.isSmallScreen) {
      return
    }
    this.previousIndex = -1
    clearTimeout(this.navTimer)
    this.navTimer = null
  }
  onClickNavItem(e, index) {
    if (this.isWideScreen) {
      return
    }
    e.preventDefault()
    if (this.animating[index] && Date.now() - this.animating[index] < SLIDE_DURATION) {
      return
    }
    this.animating[index] = Date.now()
    const currentIndex = this.currentIndex
    this.collapseAllNavItem()
    if (currentIndex === index) {
      return
    }
    this.expandNavItem(index)
  }
  onClickSearchToggler(e) {
    if (this.expandedSearch) {
      this.collapseSearch()
    } else {
      this.expandSearch()
    }
  }
  onClickLoginToggler(e) {
    if (this.expandedLogin) {
      this.collapseLogin()
    } else {
      this.expandLogin()
    }
  }
  onClickSpMenuToggler() {
    this.collapseLogin()
    const { spMenuContent } = this.elements
    if (this.expandedSpMenu) {
      this.collapseSpMenu()
      this.spMenuTimer = setTimeout(() => {
        clearTimeout(this.spMenuTimer)
        spMenuContent.scrollTo(0, 0)
      }, 300)
      if (this.headerPositionFixed) {
        spMenuContent.classList.remove('-fixed')
        spMenuContent.style.top = ''
        this.headerPositionFixed = false
        window.removeEventListener('resize', this.onResizeWindow)
      }
    } else {
      clearTimeout(this.spMenuTimer)
      const headerRect = this.elements.root.getBoundingClientRect()
      if (headerRect.top > 0) {
        this.headerPositionFixed = true
        spMenuContent.classList.add('-fixed')
        spMenuContent.style.top = `${headerRect.top + headerRect.height}px`
        window.addEventListener('resize', this.onResizeWindow)
      }
      this.expandSpMenu()
    }
  }
  onResizeWindow() {
    const headerRect = this.elements.root.getBoundingClientRect()
    this.elements.spMenuContent.style.top = `${headerRect.top + headerRect.height}px`
    this.elements.loginContent.style.top = `${headerRect.top + headerRect.height}px`
  }
  onFocusOutFromNavList(e) {
    if (this.isSmallScreen) {
      return
    }
    if (!e.relatedTarget) {
      return
    }
    const parent = e.relatedTarget.closest('.sc21-GlobalHeader-navList')
    !parent && this.collapseAllNavItem()
  }
  expandSpMenu() {
    const { spMenuToggler, spMenuContent } = this.elements
    this.expandedSpMenu = true
    this.expand(spMenuToggler, spMenuContent)
    this.isSmallScreen && this.activateScrollLock()
    window.addEventListener('resize', this.onResizeWindow)
  }
  collapseSpMenu() {
    const { spMenuToggler, spMenuContent } = this.elements
    this.expandedSpMenu = false
    this.collapseAllNavItem()
    this.collapse(spMenuToggler, spMenuContent)
    this.isSmallScreen && this.deactivateScrollLock()
    window.removeEventListener('resize', this.onResizeWindow)
  }
  expandSearch() {
    const { searchToggler, searchContent } = this.elements
    if (this.expandedLogin) {
      this.collapseLogin()
    }
    this.expandedSearch = true
    this.expand(searchToggler, searchContent)
  }
  collapseSearch() {
    const { searchToggler, searchContent } = this.elements
    this.expandedSearch = false
    this.collapse(searchToggler, searchContent)
  }
  expandLogin() {
    const { loginToggler, loginContent } = this.elements
    if (this.expandedSearch) {
      this.collapseSearch()
    }
    if (this.expandedSpMenu) {
      this.collapseSpMenu()
    }
    this.expandedLogin = true
    if (this.isSmallScreen) {
      clearTimeout(this.loginMenuTimer)
      this.activateScrollLock()
      const headerRect = this.elements.root.getBoundingClientRect()
      if (headerRect.top > 0) {
        this.headerPositionFixed = true
        loginContent.classList.add('-fixed')
        loginContent.style.top = `${headerRect.top + headerRect.height}px`
        window.addEventListener('resize', this.onResizeWindow)
      }
    }
    this.expand(loginToggler, loginContent)
  }
  collapseLogin() {
    const { loginToggler, loginContent } = this.elements
    this.expandedLogin = false
    this.collapse(loginToggler, loginContent)
    if (this.isSmallScreen) {
      this.deactivateScrollLock()
      loginContent.scrollTo(0, 0)
      this.loginMenuTimer = setTimeout(() => {
        clearTimeout(this.loginMenuTimer)
        loginContent.scrollTo(0, 0)
      }, 300)
      const headerRect = this.elements.root.getBoundingClientRect()
      if (headerRect.top > 0) {
        this.headerPositionFixed = false
        loginContent.classList.remove('-fixed')
        loginContent.style.top = ''
        window.removeEventListener('resize', this.onResizeWindow)
      }
    }
  }
  expandNavItem(index) {
    this.expandedSearch && this.collapseSearch()
    this.expandedLogin && this.collapseLogin()

    const { lists, links, contents } = this.elements
    this.currentIndex = index
    if (this.isWideScreen) {
      this.expand(links[index], contents[index], lists[index])
    } else {
      this.slideDown(links[index], contents[index], lists[index], index)
    }
  }
  collapseNavItem(index) {
    const { lists, links, contents } = this.elements
    this.currentIndex = null
    if (this.isWideScreen) {
      this.collapse(links[index], contents[index], lists[index])
    } else {
      this.slideUp(links[index], contents[index], lists[index], index)
    }
  }
  collapseAllNavItem() {
    const { links } = this.elements
    links.forEach((v, idx) => this.collapseNavItem(idx))
  }
  onKeydownTabKeySpMenuToggler(e) {
    if (!this.isSmallScreen || !this.expandedSpMenu) {
      return
    }
    if (e.key !== 'Tab' || !e.shiftKey) {
      return
    }
    e.preventDefault()
    this.elements.spMenuLastTabbable.focus()
  }
  onKeydownTabKeySpMenuLastTabbable(e) {
    if (!this.isSmallScreen || !this.expandedSpMenu) {
      return
    }
    if (e.key !== 'Tab' || e.shiftKey) {
      return
    }
    e.preventDefault()
    this.elements.spMenuToggler.focus()
  }
  onKeydownTabKeySearchToggler(e) {
    if (e.key === 'Tab' && e.shiftKey && this.expandedSearch) {
      e.preventDefault()
      this.elements.searchLastTabbable.focus()
    }
  }
  onKeydownTabKeySearchLastTabbable(e) {
    if (e.key === 'Tab' && !e.shiftKey && this.expandedSearch) {
      e.preventDefault()
      this.elements.searchToggler.focus()
    }
  }
  onKeydownTabKeyLoginToggler(e) {
    if (e.key === 'Tab' && e.shiftKey && this.expandedLogin) {
      e.preventDefault()
      this.elements.loginLastTabbable.focus()
    }
  }
  onKeydownTabKeyLoginLastTabbable(e) {
    if (e.key === 'Tab' && !e.shiftKey && this.expandedLogin) {
      e.preventDefault()
      this.elements.loginToggler.focus()
    }
  }
  onKeydownEsc(e) {
    if (e.key !== 'Escape' && e.key !== 'Esc') {
      // 'Esc' はIE用
      return
    }
    e.preventDefault()
    if (this.expandedSpMenu && this.isSmallScreen) {
      this.collapseSpMenu()
      this.elements.spMenuToggler.focus()
    }
    if (this.expandedSearch) {
      this.collapseSearch()
      this.elements.searchToggler.focus()
    }
    if (this.expandedLogin) {
      this.collapseLogin()
      this.elements.loginToggler.focus()
    }
  }
  onClickOverlay(e) {
    if (!this.isWideScreen) {
      return
    }
    if (this.expandedSearch) {
      this.collapseSearch()
      this.elements.searchToggler.focus()
    }
    if (this.expandedLogin) {
      this.collapseLogin()
      this.elements.loginToggler.focus()
    }
  }
  expand(toggler, content, list) {
    toggler.classList.add('-expanded')
    content.classList.add('-expanded')
    list && list.classList.add('-expanded')
    toggler.setAttribute('aria-expanded', 'true')
    content.setAttribute('aria-hidden', 'false')
    this.showOverlay()
  }
  collapse(toggler, content, list) {
    toggler.classList.remove('-expanded')
    content.classList.remove('-expanded')
    list && list.classList.remove('-expanded')
    toggler.setAttribute('aria-expanded', 'false')
    content.setAttribute('aria-hidden', 'true')
    this.hideOverlay()
  }
  slideDown(toggler, content, list, index) {
    toggler.classList.add('-expanded')
    content.classList.add('-expanded')
    list && list.classList.add('-expanded')
    toggler.setAttribute('aria-expanded', 'true')
    content.setAttribute('aria-hidden', 'false')
    slideDown(content, { duration: SLIDE_DURATION })
  }
  slideUp(toggler, content, list, index) {
    toggler.classList.remove('-expanded')
    content.classList.remove('-expanded')
    list && list.classList.remove('-expanded')
    toggler.setAttribute('aria-expanded', 'false')
    content.setAttribute('aria-hidden', 'true')
    slideUp(content, { duration: SLIDE_DURATION })
  }
  showOverlay() {
    const { overlay } = this.elements
    overlay.classList.add('-show')
  }
  hideOverlay() {
    const { overlay } = this.elements
    overlay.classList.remove('-show')
  }
  handleMediaChange(mq) {
    const isWide = mq.matches
    const { spMenuContent, loginContent } = this.elements
    this.hotFixBlinkSubNavWhenMediaChange()

    if (isWide) {
      // PC表示ではaria-hidden属性自体が不要
      this.collapseLogin()
      this.collapseSpMenu()
      this.deactivateScrollLock()
      if (this.canUseSticky) {
        this.observe()
      }
      this.expandedItems = []
      this.previousIndex = -1
      spMenuContent.removeAttribute('aria-hidden')
      spMenuContent.classList.remove('-fixed')
      loginContent.classList.remove('-fixed')
      spMenuContent.style.top = ''
      loginContent.style.top = ''
      window.removeEventListener('resize', this.onResizeWindow)
    } else {
      // SP表示では表示非表示を切替るためaria-hiddenが必要
      spMenuContent.setAttribute('aria-hidden', 'true')
      this.collapseLogin()
      this.collapseSearch()
      this.hideOverlay()
      if (this.canUseSticky) {
        this.disconnect()
      }
    }
  }
  hotFixBlinkSubNavWhenMediaChange() {
    // subNavは画面幅を切り替えた際にtransitionの都合で一瞬見えてしまう対策
    // TODO: いい方法があれば調整
    const { contents, spMenuContent } = this.elements
    contents.forEach(v => (v.style.display = 'none'))
    spMenuContent.style.display = 'none'

    const timer = setTimeout(() => {
      contents.forEach(v => (v.style.display = ''))
      spMenuContent.style.display = ''
      clearTimeout(timer)
    }, 200)
  }
  deactivateScrollLock() {
    document.documentElement.style.overflow = ''
    document.body.style.marginRight = ''
  }
  activateScrollLock() {
    document.documentElement.style.overflow = 'hidden'
    document.body.style.marginRight = `${scrollbarWidth}px`
  }
  observeHandler(entries) {
    const { top } = entries[0].boundingClientRect
    if (this.isHeaderFirst) {
      if (!this.isSmall && top < 82) {
        this.addSmall()
      } else if (this.isSmall && top > 60) {
        this.removeSmall()
      }
    } else {
      if (!this.isSmall && top < 1) {
        this.addSmall()
      } else if (this.isSmall && top > 0) {
        this.removeSmall()
      }
    }
  }
  addSmall() {
    this.elements.root.classList.add('-small')
    this.isSmall = true
  }
  removeSmall() {
    this.elements.root.classList.remove('-small')
    this.isSmall = false
  }
  observe() {
    this.observer.observe(this.observeTarget)
  }
  disconnect() {
    this.observer.disconnect()
    this.elements.root.classList.remove('-small')
  }
  init() {
    const {
      root,
      overlay,
      lists,
      links,
      searchToggler,
      searchLastTabbable,
      loginToggler,
      loginLastTabbable,
      spMenuToggler,
      spMenuLastTabbable,
      listWrapper,
    } = this.elements

    lists.forEach((list, idx) => {
      list.addEventListener('mouseenter', e => this.onEnterNavItem(e, idx))
      list.addEventListener('mouseleave', e => this.onLeaveNavItem(e, idx))
    })
    links.forEach((link, idx) => {
      link.addEventListener('click', e => this.onClickNavItem(e, idx))
      link.addEventListener('focus', e => this.onFocusNavItem(e, idx))
    })
    listWrapper.addEventListener('focusout', e => this.onFocusOutFromNavList(e))
    listWrapper.addEventListener('mouseleave', e => this.onLeaveNavList(e))

    searchToggler.addEventListener('click', e => this.onClickSearchToggler(e))
    searchToggler.addEventListener('keydown', e => this.onKeydownTabKeySearchToggler(e))
    searchLastTabbable.addEventListener('keydown', e => this.onKeydownTabKeySearchLastTabbable(e))

    loginToggler.addEventListener('click', e => this.onClickLoginToggler(e))
    loginToggler.addEventListener('keydown', e => this.onKeydownTabKeyLoginToggler(e))
    loginLastTabbable.addEventListener('keydown', e => this.onKeydownTabKeyLoginLastTabbable(e))

    spMenuToggler.addEventListener('click', e => this.onClickSpMenuToggler(e))
    spMenuToggler.addEventListener('keydown', e => this.onKeydownTabKeySpMenuToggler(e))
    spMenuLastTabbable.addEventListener('keydown', e => this.onKeydownTabKeySpMenuLastTabbable(e))

    window.addEventListener('keydown', e => this.onKeydownEsc(e))

    overlay.addEventListener('click', e => this.onClickOverlay(e))

    if (this.canUseSticky) {
      root.classList.add('-sticky')
      this.observeHandler = this.observeHandler.bind(this)
      this.observer = new IntersectionObserver(this.observeHandler, this.observeOptions)
    }

    this.handleMediaChange(this.matchMedia)
    this.matchMedia.addListener(mq => this.handleMediaChange(mq))

    this.onResizeWindow = this.onResizeWindow.bind(this)

    // ヘッダーの子メニューについているopacityのtransitionがChromeでロード直後に表示されるので
    // ここでフラグをつけるまでtransition系プロパティが存在しない状態にしている
    // .-headerReady ... {} ではじめてtransition系プロパティが生える
    setTimeout(() => {
      document.body.classList.add('-headerReady')
    }, 100)
  }
}

export default GlobalHeader
