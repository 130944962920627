import ChangeCardFace from '../classes/ChangeCardFace'

function initChangeCardFace() {
  const button = document.querySelector('.sc21-CreditCardDetailHeader-changeButton')
  if(!button) {
    return
  }
  new ChangeCardFace({
    button,
    header: document.querySelector('.sc21-CreditCardDetailHeader-figures'),
    application: document.querySelector('.sc21-CreditCardDetailApplication-figure'),
    footer: document.querySelector('.sc21-CreditCardDetailFootApplication-figure'),
  })
}

export default initChangeCardFace
