document.querySelectorAll('[data-card-face-changer]').forEach($el => {
  const button = $el.querySelector('[data-card-face-changer-change-button]')
  const figureList = $el.querySelectorAll('[data-card-face-changer-figure]')

  if (!button || figureList.length <= 1) {
    return
  }

  const state = {
    numOfItem: figureList.length,
    currentIndex: 0,
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.numOfItem
    },
  }

  const update = () => {
    figureList.forEach((figure, i) => {
      if (i === state.currentIndex) {
        figure.classList.remove('-hidden')
      } else {
        figure.classList.add('-hidden')
      }
    })
  }

  button.addEventListener('click', () => {
    state.next()
    update()
  })
})
