import throttle from 'raf-throttle'

class ScrollableTableHint {
  constructor({ targets, scrollContainerSelector, contentSelector }) {
    this.elements = {
      targets: Array.from(targets),
    }
    this.elements.scrollContainers = this.elements.targets.map(target => target.querySelector(scrollContainerSelector))
    this.elements.contents = this.elements.targets.map(target => target.querySelector(contentSelector))

    this.ro = null
    this.needHints = this.elements.targets.map(() => false)

    this.init()
  }

  onScroll(event) {
    const { target } = event
    if (target.scrollLeft > 10) {
      target.classList.remove('-showHint')
    } else {
      target.classList.add('-showHint')
    }
  }

  activateScrollHint(target) {
    target.classList.add('-showHint')
    target.setAttribute('tabindex', '0')
    target.addEventListener('scroll', this.onScroll)
  }

  deactivateScrollHint(target) {
    target.classList.remove('-showHint')
    target.removeAttribute('tabindex')
    target.removeEventListener('scroll', this.onScroll)
  }

  observerCallback(entries) {
    const { contents } = this.elements
    entries.forEach((entry, index) => {
      const { target } = entry
      const needHint = entry.contentRect.width + 10 < contents[index].getBoundingClientRect().width
      const needHintPrevious = this.needHints[index]
      if (needHint === needHintPrevious) {
        return
      }
      if (needHint) {
        this.activateScrollHint(target)
      } else {
        this.deactivateScrollHint(target)
      }
      this.needHints[index] = needHint
    })
  }

  init() {
    this.onScroll = throttle(this.onScroll.bind(this))
    this.observerCallback = this.observerCallback.bind(this)
    this.ro = new ResizeObserver(this.observerCallback)
    this.elements.scrollContainers.forEach(target => this.ro.observe(target))
  }
}

export default ScrollableTableHint
