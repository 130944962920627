class SearchFormExpander {
  constructor(props) {
    this.props = {
      ...props,
    }

    this.form = null
    this.expandState = false
    this.init()
  }

  onClickExpandButton() {
    const { expandTargetRoot, expandTriggerRoot, root } = this.props
    if (!this.form) {
      this.form = expandTargetRoot.querySelector('form')
    }
    const targetHeight = expandTargetRoot.firstElementChild.clientHeight
    const triggerHeight = expandTriggerRoot.firstElementChild.clientHeight
    requestAnimationFrame(() => {
      root.style.height = `${triggerHeight}px`
      requestAnimationFrame(() => {
        root.style.height = `${targetHeight}px`
        root.setAttribute('aria-expanded', 'true')
        expandTargetRoot.removeAttribute('aria-hidden')
        this.expandState = true
      })
    })
  }

  onClickCollapseButton() {
    const { expandTargetRoot, expandTriggerRoot, root } = this.props
    const targetHeight = expandTargetRoot.firstElementChild.clientHeight
    const triggerHeight = expandTriggerRoot.firstElementChild.clientHeight
    requestAnimationFrame(() => {
      root.style.height = `${targetHeight}px`
      requestAnimationFrame(() => {
        root.style.height = `${triggerHeight}px`
        root.setAttribute('aria-expanded', 'false')
        expandTargetRoot.setAttribute('aria-hidden', 'true')
        this.expandState = false
      })
    })
  }

  onTransitonendExpand(event) {
    if (event.propertyName !== 'visibility') {
      return
    }
    this.props.root.style.height = ''
    if (this.expandState && event.target === this.props.expandTargetRoot) {
      this.form.focus()
    }
    if (!this.expandState && event.target === this.props.expandTriggerRoot) {
      this.props.expandButton.focus()
    }
  }

  attachEvents() {
    this.props.expandButton.addEventListener('click', this.onClickExpandButton)
    this.props.collapseButton.addEventListener('click', this.onClickCollapseButton)
    this.props.root.addEventListener('transitionend', this.onTransitonendExpand)
  }

  init() {
    this.onClickExpandButton = this.onClickExpandButton.bind(this)
    this.onClickCollapseButton = this.onClickCollapseButton.bind(this)
    this.onTransitonendExpand = this.onTransitonendExpand.bind(this)
    this.attachEvents()
  }
}

export default SearchFormExpander
