class Disclosure {
  constructor(props) {
    this.elements = {
      ...props,
    }

    this.expended = false

    this.init()
  }

  expend() {
    const { trigger, target, content } = this.elements
    const targetHeight = content.clientHeight
    requestAnimationFrame(() => {
      target.style.height = `0px`
      requestAnimationFrame(() => {
        target.style.height = `${targetHeight}px`
        trigger.setAttribute('aria-expanded', 'true')
        target.removeAttribute('aria-hidden')
      })
    })
  }

  collapse() {
    const { trigger, target, content } = this.elements
    const targetHeight = content.clientHeight
    requestAnimationFrame(() => {
      target.style.height = `${targetHeight}px`
      requestAnimationFrame(() => {
        target.style.height = `0px`
        trigger.setAttribute('aria-expanded', 'false')
        target.setAttribute('aria-hidden', 'true')
      })
    })
  }

  onClickTrigger() {
    if (this.expended) {
      this.collapse()
    } else {
      this.expend()
    }
    this.expended = !this.expended
  }

  init() {
    this.onClickTrigger = this.onClickTrigger.bind(this)
    this.elements.trigger.addEventListener('click', this.onClickTrigger)
  }
}

export default Disclosure
