import GlobalHeader from '../classes/GlobalHeader'

function initGlobalHeader() {
  const globalHeaderRoot = document.querySelector('.sc21-GlobalHeader')
  if (!globalHeaderRoot || globalHeaderRoot.classList.contains('isSimpleGlobalLayout')) {
    return
  }
  const overlay = document.querySelector('.sc21-GlobalHeaderOverlay')
  const positionDetector = document.querySelector('.sc21-GlobalHeaderPositionDetector')
  const stickyDetector = document.querySelector('.sc21-GlobalHeaderStickyDetector')

  // 各種パーツ
  const listWrapper = globalHeaderRoot.querySelector('.sc21-GlobalHeader-navList')
  const lists = globalHeaderRoot.querySelectorAll('.sc21-GlobalHeader-navItem')
  const links = globalHeaderRoot.querySelectorAll('.sc21-GlobalHeader-navItemLink')
  const contents = globalHeaderRoot.querySelectorAll('.sc21-GlobalHeader-subNav')
  const searchContainer = globalHeaderRoot.querySelector('.sc21-GlobalHeader-navSearchContainer')
  const searchToggler = searchContainer.querySelector('.sc21-GlobalHeader-navSearchToggler')
  const searchContent = searchToggler.nextElementSibling
  const loginContainer = globalHeaderRoot.querySelector('.sc21-GlobalHeader-loginContainer')
  const loginToggler = loginContainer.querySelector('.sc21-GlobalHeader-loginToggler')
  const loginContent = loginToggler.nextElementSibling
  const spMenuToggler = globalHeaderRoot.querySelector('.sc21-GlobalHeader-spMenuToggler')
  const spMenuContent = globalHeaderRoot.querySelector('.sc21-GlobalHeader-nav')

  // 実行
  new GlobalHeader({
    root: globalHeaderRoot,
    listWrapper,
    lists,
    links,
    contents,
    overlay,
    searchContainer,
    searchToggler,
    searchContent,
    loginContainer,
    loginToggler,
    loginContent,
    spMenuToggler,
    spMenuContent,
    positionDetector,
    stickyDetector,
  })
}

export default initGlobalHeader
