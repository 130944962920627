import BasicCarousel from '../classes/BasicCarousel'
import ScrollableCarousel from '../classes/ScrollableCarousel'

const rootSelector = '.sc21-carousel'
const listSelector = '.sc21-carousel-list'
const itemSelector = '.sc21-carousel-item'
const controllerSelector = '.sc21-carousel-controllers'
const prevButtonSelector = '.sc21-carousel-button.-prev'
const nextButtonSelector = '.sc21-carousel-button.-next'
const indicatorSelector = '.sc21-carousel-indicator'

const wideCarouselRootSelector = '.sc21-wideCarousel'
const wideCarouselContentSelector = '.sc21-wideCarousel-content'

const narrowCarouselRootSelector = '.sc21-narrowCarousel'
const narrowCarouselContentSelector = '.sc21-narrowCarousel-content'

function initCarousel() {
  const targets = document.querySelectorAll(rootSelector)
  if (!targets || !targets.length) {
    return
  }
  targets.forEach(element => {
    initScrollableCarousel(element)
    initBasicCarousel(element)
  })
}

function initBasicCarousel(root) {
  const wideCarouselRoot = root.querySelector(wideCarouselRootSelector)

  // SP用カルーセルしかない場合があるため、判定が必要
  if (wideCarouselRoot) {
    new BasicCarousel({
      root: wideCarouselRoot,
      contentSelector: wideCarouselContentSelector,
      listSelector,
      itemSelector,
      controllerSelector,
      prevButtonSelector,
      nextButtonSelector,
      indicatorSelector,
      indicatorClassNamePrefix: 'sc21-carousel',
      threshold: 0.2,
      splitCount: 4,
    })
  }
}

function initScrollableCarousel(root) {
  const narrowCarouselRoot = root.querySelector(narrowCarouselRootSelector)

  new ScrollableCarousel({
    root: narrowCarouselRoot,
    contentSelector: narrowCarouselContentSelector,
    listSelector,
    controllerSelector,
    prevButtonSelector,
    nextButtonSelector,
    indicatorSelector,
    indicatorClassNamePrefix: 'sc21-carousel',
    // 左からどのくらいを交差判定にするか（百分率）
    thresholdX: 0.25,
  })
}

export default initCarousel
