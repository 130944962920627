import ScrollableTableHint from '../classes/ScrollableTableHint'

function initScrollableTableHint() {
  // TODO: 古いモジュール用なので、入れ替えたら消す
  const scrollableTables = document.querySelectorAll('.sc21-tableWrapper-scrollable')
  if (scrollableTables.length) {
    new ScrollableTableHint({
      targets: scrollableTables,
      scrollContainerSelector: '.sc21-tableWrapper-scrollableInner',
      contentSelector: '.sc21-tableWrapper-content',
    })
  }
  const scrollableTablesNew = document.querySelectorAll('.sc21-TBL-scroll__scrollable')
  if (scrollableTablesNew.length) {
    new ScrollableTableHint({
      targets: scrollableTablesNew,
      scrollContainerSelector: '.sc21-TBL-scroll__inner',
      contentSelector: '.sc21-TBL-scroll__content',
    })
  }
}

export default initScrollableTableHint
