import Disclosure from '../classes/Disclosure'

function initDisclosure() {
  const disclosureRoots = document.querySelectorAll('.sc21-disclosure')
  disclosureRoots.forEach(root => {
    new Disclosure({
      root,
      trigger: root.querySelector('.sc21-disclosure-trigger'),
      target: root.querySelector('.sc21-disclosure-target'),
      content: root.querySelector('.sc21-disclosure-content'),
    })
  })
}

export default initDisclosure
