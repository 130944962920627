import Swiper from 'swiper'
import { Navigation, Pagination, Mousewheel } from 'swiper/modules'

const mql = window.matchMedia('(max-width: 767px)')

const isPreviewPage = !!document.querySelectorAll('[data-periodic-payments-carousel-preview]').length

const startInit = () => {
  document.querySelectorAll('.sc21-Carousel2').forEach(swiperEl => {
    const numOfSlides = swiperEl.querySelectorAll('.sc21-Carousel2-item').length
    swiperEl.classList.add(`-slides${numOfSlides}`)

    const slidesPerViewL = +swiperEl.getAttribute('data-slides-per-view-l') || 'auto'
    const slidesPerViewS = +swiperEl.getAttribute('data-slides-per-view-s') || 'auto'

    const swiperRef = { current: null }

    const initSwiper = () => {
      swiperRef.current = new Swiper(swiperEl, {
        modules: [Navigation, Pagination, Mousewheel],
        wrapperClass: 'sc21-Carousel2-wrapper',
        slideClass: 'sc21-Carousel2-item',
        slidesPerView: slidesPerViewS,
        spaceBetween: 16,
        speed: 380,
        navigation: {
          nextEl: swiperEl.querySelector('.sc21-Carousel2-nextButton'),
          prevEl: swiperEl.querySelector('.sc21-Carousel2-prevButton'),
        },
        pagination: {
          el: swiperEl.querySelector('.sc21-Carousel2-pagination'),
          bulletElement: 'button',
          clickable: true,
        },
        mousewheel: {
          forceToAxis: true,
          releaseOnEdges: true,
        },
        breakpoints: {
          768: {
            slidesPerView: slidesPerViewL,
            spaceBetween: 32,
            allowTouchMove: false,
            mousewheel: false,
          },
        },
      })
    }

    initSwiper()

    mql.addEventListener('change', () => {
      swiperRef.current.destroy()
      initSwiper()
    })
  })
}

// CMSプレビュー画面においては、このスクリプトが実行されるタイミングで
// 対象となる要素が準備されていない可能性がある。
// ただし必ずカルーセルが登場する想定なので、要素が確認できるまでチェックを繰り返す

if (isPreviewPage) {
  const initLater = () => {
    setTimeout(() => {
      const previewReady = !!document.querySelectorAll('.sc21-Carousel2').length
      if (!previewReady) {
        initLater()
        return
      }
      startInit()
    }, 1000)
  }
  initLater()
} else {
  startInit()
}
