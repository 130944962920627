import { slideDown, slideUp, slideStop } from 'slide-anim'

class GlobalFooter {
  constructor({ root, toggler, content }) {
    this.expandedIndex = null
    this.elements = {
      root,
      toggler,
      content,
    }
    this.matchMedia = window.matchMedia('screen and (max-width: 767px)')
    this.init()
  }
  get isWideScreen() {
    return this.matchMedia.matches
  }
  get isSmallScreen() {
    return !this.isWideScreen
  }
  onClickToggler(e, index) {
    const expandedIndex = this.expandedIndex

    if (index === expandedIndex) {
      this.collapse(index)
      return
    }

    if (expandedIndex !== null) {
      this.collapse(expandedIndex)
    }

    this.expand(index)
  }
  expand(index) {
    const { toggler, content } = this.elements
    const t = toggler[index]
    const c = content[index]

    t.setAttribute('aria-expanded', 'true')
    t.classList.remove('-collapsed')
    t.classList.add('-expanded')
    slideStop(c)
    slideDown(c)

    this.expandedIndex = index
  }
  collapse(index) {
    const { toggler, content } = this.elements
    const t = toggler[index]
    const c = content[index]

    t.setAttribute('aria-expanded', 'false')
    t.classList.remove('-expanded')
    t.classList.add('-collapsed')
    slideStop(c)
    slideUp(c)

    this.expandedIndex = null
  }
  collapseAll() {
    const { content } = this.elements
    for (let i = 0; i < content.length; i++) {
      this.collapse(i)
    }
  }
  handleMediaChange(mq) {
    const isWide = !mq.matches

    if (isWide) {
      this.cleanupForPC()
    } else {
      this.cleanupForSP()
    }
  }
  cleanupForPC() {
    const { toggler, content } = this.elements

    toggler.forEach(v => (v.disabled = true))
    content.forEach(v => (v.style.display = ''))
  }
  cleanupForSP() {
    const { toggler } = this.elements

    toggler.forEach(v => v.removeAttribute('disabled'))
    this.collapseAll()
  }
  init() {
    const { toggler } = this.elements

    toggler.forEach((v, idx) => v.addEventListener('click', e => this.onClickToggler(e, idx)))

    this.handleMediaChange(this.matchMedia)
    this.matchMedia.addListener(mq => this.handleMediaChange(mq))
  }
}

export default GlobalFooter
