import throttle from 'raf-throttle'
import { createCarouselIndicatorForSmall } from '../utils/createCarouselIndicator'
import scrollbarWidth from '../utils/scrollbarWidth'

class ScrollableCarousel {
  constructor(props) {
    const { root } = props

    this.props = {
      ...props,
    }

    this.elements = {
      root,
      content: root.querySelector(props.contentSelector),
      controller: root.querySelector(props.controllerSelector),
      list: root.querySelector(props.listSelector),
      prevButton: root.querySelector(props.prevButtonSelector),
      nextButton: root.querySelector(props.nextButtonSelector),
      indicator: root.querySelector(props.indicatorSelector),
      dots: null,
    }
    this.elements.items = Array.from(this.elements.list.children)

    this.observer = null
    this.observerOptions = {
      root: this.elements.content,
      threshold: 0,
      rootMargin: [
        `0px`, // top
        `-${(1 - this.props.thresholdX) * 100}%`, // right
        '0px', // bottom
        `-${this.props.thresholdX * 100}%`, // left
      ].join(' '),
    }

    this.itemsCount = this.elements.items.length
    this.lastIndex = this.itemsCount - 1

    // status
    this.currentIndex = -1
    this.controllersDisabledStatus = {
      prev: false,
      next: false,
    }

    this.lastScrollTime = null

    // スクロールバーを隠す
    this.hideScrollbar()

    // アイテムが1つの場合はコントローラー消して終了
    if (this.itemsCount > 1) {
      this.init()
    } else {
      this.hideControllers()
    }
  }

  // is scrolling

  set lastScroll(time) {
    this.lastScrollTime = time
  }

  get lastScroll() {
    return this.lastScrollTime
  }

  get isScrolling() {
    return this.lastScroll && new Date().getTime() < this.lastScroll + 100
  }

  // create DOM

  createIndicator() {
    const nav = createCarouselIndicatorForSmall(this.itemsCount, this.props.indicatorClassNamePrefix)
    this.elements.indicator.appendChild(nav.list)
    this.elements.dots = nav.buttons
  }

  // hide

  hideControllers() {
    const { controller } = this.elements
    controller.style.display = 'none'
    controller.inert = true
  }

  hideScrollbar() {
    this.elements.content.parentElement.style.overflow = 'hidden'
    this.elements.content.style.marginBottom = `-${scrollbarWidth}px`
  }

  // change status

  updateControllerDisabledProp(button, key, disablingCondition) {
    const needUpdate = disablingCondition !== this.controllersDisabledStatus[key]
    if (!needUpdate) {
      return
    }
    button.disabled = disablingCondition
    this.controllersDisabledStatus[key] = disablingCondition
  }

  updateIndicatorDisabledProp(index) {
    if (this.currentIndex > -1) {
      this.elements.dots[this.currentIndex].disabled = false
      this.elements.dots[this.currentIndex].removeAttribute('aria-current')
    }
    this.elements.dots[index].disabled = true
    this.elements.dots[index].setAttribute('aria-current', 'true')
  }

  updateStatus(index) {
    this.updateControllerDisabledProp(this.elements.prevButton, 'prev', index === 0)
    this.updateControllerDisabledProp(this.elements.nextButton, 'next', index === this.lastIndex)
    this.updateIndicatorDisabledProp(index)
    this.currentIndex = index
  }

  slideAnim(index) {
    const target = this.elements.items[index]
    this.elements.content.scrollTo({
      top: 0,
      left: target.offsetLeft,
      behavior: 'smooth',
    })
  }

  changeItem(index) {
    if (index === this.currentIndex) {
      return
    }
    this.slideAnim(index)
  }

  // controller click event handlers

  onClickPrevButton(event) {
    event.preventDefault()
    if (this.isScrolling) {
      return
    }
    const prevIndex = this.currentIndex - 1
    if (prevIndex < 0) {
      return
    }
    this.changeItem(prevIndex)
  }

  onClickNextButton(event) {
    event.preventDefault()
    if (this.isScrolling) {
      return
    }
    const nextIndex = this.currentIndex + 1
    if (nextIndex > this.lastIndex) {
      return
    }
    this.changeItem(nextIndex)
  }

  onClickIndicatorButton(event) {
    event.preventDefault()
    if (this.isScrolling) {
      return
    }
    const { target } = event
    if (target.tagName.toLowerCase() !== 'button') {
      return
    }
    const index = this.elements.dots.indexOf(target)
    this.changeItem(index)
  }

  onScrollContent() {
    this.lastScroll = new Date().getTime()
  }

  // intersection observer handler

  observeHandler(entries) {
    // 以下の前提条件があるので、この要件を満たすようにオプションとスタイルを設定する
    // - 交差判定が発生する要素は2つだけ
    // - 交差中である要素は1つだけ
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const index = this.elements.items.indexOf(entry.target)
        this.updateStatus(index)
      }
    }
  }

  // initialize

  attachEvents() {
    const { content, prevButton, nextButton, indicator } = this.elements
    prevButton.addEventListener('click', this.onClickPrevButton, false)
    nextButton.addEventListener('click', this.onClickNextButton, false)
    indicator.addEventListener('click', this.onClickIndicatorButton, false)
    content.addEventListener('scroll', this.onScrollContent, false)
  }

  initIntersectionObserver() {
    this.observer = new IntersectionObserver(this.observeHandler, this.observerOptions)
    this.elements.items.forEach(element => {
      this.observer.observe(element)
    })
  }

  init() {
    this.createIndicator()
    this.onScrollContent = throttle(this.onScrollContent.bind(this))
    this.onClickPrevButton = this.onClickPrevButton.bind(this)
    this.onClickNextButton = this.onClickNextButton.bind(this)
    this.onClickIndicatorButton = this.onClickIndicatorButton.bind(this)
    this.observeHandler = this.observeHandler.bind(this)
    this.attachEvents()
    this.initIntersectionObserver()
    this.changeItem(0)
  }
}

export default ScrollableCarousel
