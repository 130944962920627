import { chunk as _chunk } from 'lodash/array'
import throttle from 'raf-throttle'
import { createCarouselIndicatorForWide } from '../utils/createCarouselIndicator'

class BasicCarousel {
  constructor(props) {
    const { root } = props

    this.props = {
      ...props,
    }

    // elements
    this.elements = {
      root,
      content: root.querySelector(props.contentSelector),
      controller: root.querySelector(props.controllerSelector),
      list: root.querySelector(props.listSelector),
      items: root.querySelectorAll(props.itemSelector),
      prevButton: root.querySelector(props.prevButtonSelector),
      nextButton: root.querySelector(props.nextButtonSelector),
      indicator: root.querySelector(props.indicatorSelector),
    }

    this.elements.itemsByPanel = _chunk([...this.elements.items], this.props.splitCount)
    this.itemsCount = this.elements.items.length
    this.panelsCount = this.elements.itemsByPanel.length

    const lastPanelItemsCount = this.itemsCount % 4 || 4
    this.lastPanelItems = [...this.elements.items].slice(-4)
    this.lastIndex = this.panelsCount - 1
    this.lastPanelTranslatePercentage = -(100 * (this.lastIndex - 1) + (100 / 4) * lastPanelItemsCount)

    const nav = createCarouselIndicatorForWide(
      this.panelsCount,
      lastPanelItemsCount,
      this.props.indicatorClassNamePrefix
    )
    this.elements.indicator.appendChild(nav.list)
    this.elements.dots = nav.buttons

    // status
    this.currentIndex = -1
    this.currentTransformValue = ''

    this.controllersDisabledStatus = {
      prev: false,
      next: false,
    }

    this.pointer = {
      startX: 0,
      moveX: 0,
      hold: false,
    }

    if (this.panelsCount > 1) {
      this.init()
    } else {
      this.hideControllers()
    }
  }

  // hide
  hideControllers() {
    const { controller } = this.elements
    controller.style.display = 'none'
    controller.inert = true
  }

  // change status

  updateControllerDisabledProp(button, key, disablingCondition) {
    const needUpdate = disablingCondition !== this.controllersDisabledStatus[key]
    if (!needUpdate) {
      return
    }
    button.disabled = disablingCondition
    this.controllersDisabledStatus[key] = disablingCondition
  }

  updateIndicatorDisabledProp(index) {
    if (this.currentIndex > -1) {
      this.elements.dots[this.currentIndex].disabled = false
      this.elements.dots[this.currentIndex].removeAttribute('aria-current')
    }
    this.elements.dots[index].disabled = true
    this.elements.dots[index].setAttribute('aria-current', 'true')
  }

  updateItemsInert(index) {
    const panels = this.elements.itemsByPanel
    if (this.currentIndex > -1) {
      panels[this.currentIndex].forEach(item => {
        item.inert = true
      })
    }
    const disableInertTargets = index === this.lastIndex ? this.lastPanelItems : panels[index]
    disableInertTargets.forEach(item => {
      item.inert = false
    })
  }

  slideAnim(index) {
    const distance = index === this.lastIndex ? this.lastPanelTranslatePercentage : -100 * index
    const transformValue = `translateX(${distance}%)`
    this.elements.list.style.transform = transformValue
    this.currentTransformValue = transformValue
  }

  changeItem(index) {
    if (index === this.currentIndex) {
      return
    }
    this.slideAnim(index)
    this.updateControllerDisabledProp(this.elements.prevButton, 'prev', index === 0)
    this.updateControllerDisabledProp(this.elements.nextButton, 'next', index === this.lastIndex)
    this.updateIndicatorDisabledProp(index)
    this.updateItemsInert(index)
    this.currentIndex = index
  }

  // controller click event handlers

  onClickPrevButton(event) {
    event.preventDefault()
    const prevIndex = this.currentIndex - 1
    if (prevIndex < 0) {
      return
    }
    this.changeItem(prevIndex)
  }

  onClickNextButton(event) {
    event.preventDefault()
    const nextIndex = this.currentIndex + 1
    if (nextIndex > this.lastIndex) {
      return
    }
    this.changeItem(nextIndex)
  }

  onClickIndicatorButton(event) {
    event.preventDefault()
    const { target } = event
    if (target.tagName.toLowerCase() !== 'button') {
      return
    }
    const index = this.elements.dots.indexOf(target)
    this.changeItem(index)
  }

  // touch action event handlers

  pointerDown(clientX) {
    this.pointer.startX = clientX
    this.pointer.hold = true
    this.elements.list.style.transitionDuration = '0s'
  }

  onTouchstart(event) {
    if (event.targetTouches.length > 1) {
      return
    }
    this.pointerDown(event.targetTouches[0].clientX)
  }

  onPointerdown(event) {
    if (event.pointerType !== 'touch' || !event.isPrimary) {
      return
    }
    event.preventDefault()
    this.pointerDown(event.clientX)
  }

  pointerMove(clientX) {
    const moveX = clientX - this.pointer.startX
    const additionalTransformValue = `translateX(${moveX}px)`
    this.pointer.moveX = moveX
    this.elements.list.style.transform = `${this.currentTransformValue} ${additionalTransformValue}`
  }

  onTouchmove(event) {
    if (event.targetTouches.length > 1 || !this.pointer.hold) {
      return
    }
    this.pointerMove(event.targetTouches[0].clientX)
  }

  onPointermove(event) {
    if (event.pointerType !== 'touch' || !event.isPrimary || !this.pointer.hold) {
      return
    }
    event.preventDefault()
    this.pointerMove(event.clientX)
  }

  getIndexSwipeEnd() {
    const absoluteMoveX = Math.abs(this.pointer.moveX)
    const addIndex = this.pointer.moveX > 0 ? -1 : 1
    if (
      absoluteMoveX < this.elements.list.clientWidth * this.props.threshold ||
      (this.currentIndex === 0 && addIndex === -1) ||
      (this.currentIndex === this.lastIndex && addIndex === 1)
    ) {
      return this.currentIndex
    }
    return this.currentIndex + addIndex
  }

  pointerUp() {
    const nextIndex = this.getIndexSwipeEnd()
    this.elements.list.style.transitionDuration = ''
    if (nextIndex === this.currentIndex) {
      this.elements.list.style.transform = this.currentTransformValue
    } else {
      this.changeItem(nextIndex)
    }
    this.pointer.startX = 0
    this.pointer.moveX = 0
    this.pointer.hold = false
  }

  onTouchend(event) {
    if (event.targetTouches.length > 1 || !this.pointer.hold) {
      return
    }
    this.pointerUp()
  }

  onPointerup(event) {
    if (event.pointerType !== 'touch' || !event.isPrimary || !this.pointer.hold) {
      return
    }
    event.preventDefault()
    this.pointerUp()
  }

  // initialize

  attachEvents() {
    const { content, prevButton, nextButton, indicator } = this.elements

    this.onClickPrevButton = this.onClickPrevButton.bind(this)
    this.onClickNextButton = this.onClickNextButton.bind(this)
    this.onClickIndicatorButton = this.onClickIndicatorButton.bind(this)

    prevButton.addEventListener('click', this.onClickPrevButton, false)
    nextButton.addEventListener('click', this.onClickNextButton, false)
    indicator.addEventListener('click', this.onClickIndicatorButton, false)

    if ('PointerEvent' in window) {
      this.onPointerdown = this.onPointerdown.bind(this)
      this.onPointermove = throttle(this.onPointermove.bind(this))
      this.onPointerup = this.onPointerup.bind(this)
      content.addEventListener('pointerdown', this.onPointerdown, false)
      content.addEventListener('pointermove', this.onPointermove, false)
      content.addEventListener('pointerup', this.onPointerup, false)
      content.addEventListener('pointercancel', this.onPointerup, false)
      content.style.touchAction = 'none'
    } else if ('ontouchstart' in window) {
      this.onTouchdown = this.onTouchdown.bind(this)
      this.onTouchmove = throttle(this.onTouchmove.bind(this))
      this.onTouchup = this.onTouchup.bind(this)
      content.addEventListener('touchstart', this.onTouchstart, false)
      content.addEventListener('touchmove', this.onTouchmove, false)
      content.addEventListener('touchend', this.onTouchend, false)
      content.addEventListener('touchcancel', this.onTouchend, false)
    }
  }

  initInert(targetIndex) {
    this.elements.itemsByPanel.forEach((panel, index) => {
      if (targetIndex === index) {
        return
      }
      panel.forEach(item => {
        item.inert = true
      })
    })
  }

  init() {
    this.attachEvents()
    this.initInert(0)
    this.changeItem(0)
  }
}

export default BasicCarousel
