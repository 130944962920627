class ChangeCardFace {
  constructor(props) {
    this.elements = {
      ...props,
      headerChildren: props.header.children,
      applicationChildren: props.application.children,
      footerChildren: props.footer.children,
    }

    this.childrenLength = this.elements.headerChildren.length

    if (
      this.childrenLength !== this.elements.applicationChildren.length ||
      this.childrenLength !== this.elements.footerChildren.length
    ) {
      return
    }

    this.currentIndex = 0
    this.lastIndex = this.childrenLength - 1

    this.init()
  }

  getNextIndex() {
    const nextIndex = this.currentIndex + 1
    return nextIndex > this.lastIndex ? 0 : nextIndex
  }

  changeCardFace(currentIndex, nextIndex) {
    const { headerChildren, applicationChildren, footerChildren } = this.elements
    const childrenGroup = [headerChildren, applicationChildren, footerChildren]
    for (const children of childrenGroup) {
      children[this.currentIndex].classList.add('-hide')
      children[nextIndex].classList.remove('-hide')
    }
  }

  onClickButton() {
    const nextIndex = this.getNextIndex()
    this.changeCardFace(this.currentIndex, nextIndex)
    this.currentIndex = nextIndex
  }

  init() {
    this.onClickButton = this.onClickButton.bind(this)
    this.elements.button.addEventListener('click', this.onClickButton)
  }
}

export default ChangeCardFace
