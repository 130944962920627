import TabUI from '../classes/TabUI'

function initTabUI() {
  const tabRoots = document.querySelectorAll('.sc21-TAB')
  tabRoots.forEach(root => {
    new TabUI({
      root,
      tabSelector: '.sc21-TAB-tab',
      panelSelector: '.sc21-TAB-tabpanel',
    })
  })
}

export default initTabUI
