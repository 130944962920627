import ScrollableChecker from '../classes/ScrollableChecker'

function scrollableChecker({ targetSelector, targetChildSelector }) {
  const targets = document.querySelectorAll(targetSelector)
  if (!targets || !targets.length) {
    return
  }
  const callback = attachScrollableCheck.bind(null, targetChildSelector)
  targets.forEach(callback)
}

function attachScrollableCheck(childSelector, target) {
  const child = target.querySelector(childSelector)
  if (!child) {
    return
  }
  new ScrollableChecker({
    target,
    child,
  })
}

export default scrollableChecker
