import StickyCreditCardDetailApplicationHeader from '../classes/StickyCreditCardDetailApplicationHeader.js'
import StickyCreditCardDetailAnchors from '../classes/StickyCreditCardDetailAnchors.js'

function initCreditCardDetailStickies() {
  const creditCardDetailApplicationHeader = document.querySelector('.sc21-CreditCardDetailBody-application')
  const creditCardDetailApplicationObserveTarget = document.querySelector('.sc21-CreditCardDetailBody-main')
  const creditCardDetailAnchors = document.querySelector('.sc21-CreditCardDetail-anchors')
  const creditCardDetailObserveTarget = document.querySelector('.sc21-CreditCardDetail-body')
  const ApplicationButton = document.querySelector('.sc21-CreditCardDetailHeader-action')
  if (
    !creditCardDetailApplicationHeader ||
    !creditCardDetailApplicationObserveTarget ||
    !creditCardDetailAnchors ||
    !creditCardDetailObserveTarget ||
    !ApplicationButton
  ) {
    return
  }

  const stickyHeaderObserver = new StickyCreditCardDetailApplicationHeader({
    stickyHeader: creditCardDetailApplicationHeader,
    observeTarget: creditCardDetailApplicationObserveTarget,
  })

  const stickyAnchorsObserver = new StickyCreditCardDetailAnchors({
    stickyTarget: creditCardDetailAnchors,
    observeTarget: creditCardDetailObserveTarget,
    anchorSelector: '.sc21-CreditCardDetailAnchors-link:not(.-logo)',
  })

  const mq = matchMedia('(max-width: 767px)')
  try {
    mq.addEventListener('change', changeObserve)
  } catch (e) {
    mq.addListener(changeObserve)
  }
  changeObserve(mq)

  function changeObserve(mq) {
    if (mq.matches) {
      stickyHeaderObserver.observe()
      stickyAnchorsObserver.disconnect()
    } else {
      stickyHeaderObserver.disconnect()
      stickyAnchorsObserver.observe()
    }
  }
}

export default initCreditCardDetailStickies
