import { slideDown, slideUp, slideStop } from 'slide-anim'

class Accordion {
  constructor(props) {
    this.props = {
      ...props,
    }

    const { root, items } = this.props

    this.elements = {
      root,
      items: Array.from(items),
      triggers: [],
      targets: [],
    }
    this.expandStatus = []

    if (!this.elements.items || !this.elements.items.length) {
      return
    }

    this.elements.items.forEach((item, index) => {
      const trigger = item.querySelector(this.props.triggerSelector)
      const target = item.querySelector(this.props.targetSelector)
      this.elements.triggers.push(trigger)
      this.elements.targets.push(target)
      this.expandStatus.push(trigger.getAttribute('aria-expanded') === 'true')
    })

    this.init()
  }

  expand(index) {
    const { triggers, targets } = this.elements
    const content = targets[index]
    triggers[index].setAttribute('aria-expanded', 'true')
    content.setAttribute('aria-hidden', 'false')
    this.expandStatus[index] = true
    slideStop(content)
    slideDown(content)
  }

  collapse(index) {
    const { triggers, targets } = this.elements
    const content = targets[index]
    triggers[index].setAttribute('aria-expanded', 'false')
    content.setAttribute('aria-hidden', 'true')
    this.expandStatus[index] = false
    slideStop(content)
    slideUp(content)
  }

  onClickTrigger(event) {
    const index = this.elements.triggers.indexOf(event.target)
    if (index < 0) {
      return
    }
    if (this.expandStatus[index]) {
      this.collapse(index)
    } else {
      this.expand(index)
    }
  }

  attachEvents() {
    this.elements.root.addEventListener('click', this.onClickTrigger, false)
  }

  init() {
    this.onClickTrigger = this.onClickTrigger.bind(this)
    this.attachEvents()
  }
}

export default Accordion
