import YouTubePlayer from '../classes/YouTubePlayer'

function initYouTubePlayer() {
  const roots = document.querySelectorAll('.sc21-ytVideoPlayer')

  if (!roots.length) {
    return
  }

  if (!('YT' in window)) {
    loadAPI()
  }
  if (!('onYouTubeIframeAPIReady' in window)) {
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady.bind(this, roots)
  }
}

function loadAPI() {
  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

function onYouTubeIframeAPIReady(roots) {
  roots.forEach(root => {
    new YouTubePlayer({
      root,
      videoId: root.dataset.videoId,
      buttonSelector: '.sc21-ytVideoPlayer-button',
      playerSelector: '.sc21-ytVideoPlayer-player',
      transitionTargetSelector: '.sc21-ytVideoPlayer-stage',
      transitionProperty: 'visibility',
    })
  })
}

export default initYouTubePlayer
