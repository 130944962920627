function homeInfiniteBannerController() {
  const button = document.querySelector('.sc21-Home-shoppingControlButton')
  if (!button) {
    return
  }
  const river = document.querySelector('.sc21-Home-shoppingRiver')
  button.addEventListener('click', event => {
    event.preventDefault()
    river.classList.toggle('-paused')
    button.classList.toggle('-paused')
  })
}

export default homeInfiniteBannerController
