class StickyCreditCardDetailAnchors {
  constructor(props) {
    this.props = {
      ...props,
    }
    this.observeOptions = {
      sticky: {
        threshold: 0.0,
        rootMargin: '0px 0px -100% 0px',
      },
      view: {
        threshold: 0,
        rootMargin: '-50% 0px',
      },
    }
    this.stickyObserver = null
    this.viewObserver = null
    this.init()
  }

  setElements() {
    const anchors = Array.from(this.props.stickyTarget.querySelectorAll(this.props.anchorSelector))
    const anchorTargets = anchors.map((element, index) => {
      const href = element.getAttribute('href')
      const target = document.querySelector(href)
      target.dataset.anchorsIndex = index.toString()
      return target
    })
    this.anchors = anchors
    this.anchorsLastIndex = anchors.length - 1
    this.anchorTargets = anchorTargets
    this.transitionTarget = this.props.stickyTarget.firstElementChild
  }

  enableSticky() {
    const { stickyTarget } = this.props
    this.transitionTarget.style.transitionProperty = 'none'
    stickyTarget.classList.add('-sticky')
    requestAnimationFrame(() => {
      this.transitionTarget.style.transitionProperty = ''
      requestAnimationFrame(() => {
        stickyTarget.classList.add('-show')
      })
    })
  }

  disableSticky() {
    this.props.stickyTarget.classList.remove('-show')
  }

  allAnchorsInPast() {
    this.anchors.forEach(anchor => {
      anchor.classList.add('-past')
      anchor.classList.remove('-current')
    })
  }

  stickyObserveHandler(entries) {
    const { top, bottom } = entries[0].boundingClientRect
    if (top < 0) {
      this.enableSticky()
    } else {
      this.disableSticky()
    }
    if (bottom < 0) {
      this.allAnchorsInPast()
    }
  }

  changeAnchorState(entry) {
    if (!entry.isIntersecting) {
      return
    }
    const currentIndex = parseInt(entry.target.dataset.anchorsIndex, 10)
    this.anchors.forEach((anchor, index) => {
      if (index < currentIndex) {
        anchor.classList.add('-past')
        anchor.classList.remove('-current')
        return
      }
      if (index > currentIndex) {
        anchor.classList.remove('-past')
        anchor.classList.remove('-current')
        return
      }
      anchor.classList.remove('-past')
      anchor.classList.add('-current')
    })
  }

  viewObserveHandler(entries) {
    entries.forEach(this.changeAnchorState)
  }

  onTransitionend(event) {
    if (event.target !== this.transitionTarget) {
      return
    }
    if (event.currentTarget.classList.contains('-show')) {
      return
    }
    event.currentTarget.classList.remove('-sticky')
  }

  observe() {
    this.stickyObserver.observe(this.props.observeTarget)
    for (const element of this.anchorTargets) {
      this.viewObserver.observe(element)
    }
  }

  disconnect() {
    this.stickyObserver.disconnect()
    this.viewObserver.disconnect()
    this.props.stickyTarget.classList.remove('-sticky')
    for (const anchor of this.anchors) {
      anchor.classList.remove('-past')
      anchor.classList.remove('-current')
    }
  }

  init() {
    this.stickyObserveHandler = this.stickyObserveHandler.bind(this)
    this.viewObserveHandler = this.viewObserveHandler.bind(this)
    this.changeAnchorState = this.changeAnchorState.bind(this)
    this.onTransitionend = this.onTransitionend.bind(this)
    this.setElements()
    this.stickyObserver = new IntersectionObserver(this.stickyObserveHandler, this.observeOptions.sticky)
    this.viewObserver = new IntersectionObserver(this.viewObserveHandler, this.observeOptions.view)
    this.props.stickyTarget.addEventListener('transitionend', this.onTransitionend)
  }
}

export default StickyCreditCardDetailAnchors
