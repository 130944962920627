import SearchFormExpander from '../classes/SearchFormExpander'

function searchFormExpander() {
  const targets = document.querySelectorAll('.sc21-CreditCardSearch')
  if (!targets || !targets.length) {
    return
  }
  targets.forEach(attachScrollableCheck)
}

function attachScrollableCheck(target) {
  const root = target
  const expandTriggerRoot = target.querySelector('.sc21-CreditCardSearch-header')
  const expandTargetRoot = target.querySelector('.sc21-CreditCardSearch-body')
  const expandButton = target.querySelector('.sc21-CreditCardSearch-openButton')
  const collapseButton = target.querySelector('.sc21-CreditCardSearch-closeButton')
  new SearchFormExpander({
    root,
    expandTriggerRoot,
    expandTargetRoot,
    expandButton,
    collapseButton,
  })
}

export default searchFormExpander
