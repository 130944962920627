class ScrollableChecker {
  constructor({ target, child }) {
    this.target = target
    this.child = child
    this.width = {
      parent: 0,
      child: 0,
    }
    this.scrollable = false
    this.ro = null
    this.init()
  }

  toggleClassName() {
    if (!this.scrollable) {
      this.target.classList.remove('-scrollable')
    } else {
      this.target.classList.add('-scrollable')
    }
  }

  observerCallback(entries) {
    const parent = entries[0]
    const child = entries[1]

    if (parent) {
      this.width.parent = parent.contentRect.width
    }

    if (child) {
      this.width.child = child.contentRect.width
    }

    if (this.width.parent < this.width.child) {
      if (!this.scrollable) {
        this.scrollable = true
        this.toggleClassName()
      }
    } else {
      if (this.scrollable) {
        this.scrollable = false
        this.toggleClassName()
      }
    }
  }

  init() {
    this.observerCallback = this.observerCallback.bind(this)
    this.ro = new ResizeObserver(this.observerCallback)
    this.ro.observe(this.target)
    this.ro.observe(this.child)
  }
}

export default ScrollableChecker
