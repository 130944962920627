function shouldEnableFeature() {
  // 自動チャットの画面では何故か止まるので有効にする
  if (location.pathname === '/contact/') {
    return true
  }
  // CSSでできる環境では使わない
  if ('scrollBehavior' in document.documentElement.style) {
    return false
  }
  return true
}

function scrollBehaviorPolyfill() {
  if (!shouldEnableFeature()) {
    return
  }
  const anchors = document.querySelectorAll('a[href^="#"]')
  const mql = window.matchMedia('screen and (max-width: 767px)')
  const styleElement = document.createElement('style')
  document.head.appendChild(styleElement)
  const styleSheet = styleElement.sheet
  anchors.forEach(anchor => {
    anchor.addEventListener('click', (event, index) => {
      event.preventDefault()
      const target = event.currentTarget
      // URLエンコードされたidはエスケープしないと不正なセレクタになる
      const targetId = target.hash.split('%').join('\\%')
      const targetElement = document.querySelector(targetId)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const buffer = mql.matches ? 70 : 100
      const top = rectTop + offsetTop - buffer
      const tabindex = targetElement.getAttribute('tabindex')
      if (!tabindex) {
        targetElement.setAttribute('tabindex', '-1')
        if (!styleSheet.cssRules[index]) {
          styleSheet.insertRule(`${targetId}:focus { outline: 0 }`, index)
        }
      }
      targetElement.focus()
      window.scrollTo({
        top,
        behavior: 'smooth',
      })
      if (!tabindex) {
        targetElement.removeAttribute('tabindex')
      }
    })
  })
}

export default scrollBehaviorPolyfill
