function createCarouselIndicatorForWide(count, lastPanelItemsCount, classNamePrefix) {
  const fragment = document.createDocumentFragment()
  const buttons = []
  for (let i = 0; i < count; i++) {
    const li = document.createElement('li')
    const text = getText(i, count, lastPanelItemsCount)
    const button = createDot([`${classNamePrefix}-dot`], text)
    li.classList.add(`${classNamePrefix}-indicatorItem`)
    li.appendChild(button)
    fragment.appendChild(li)
    buttons.push(button)
  }

  return {
    list: fragment,
    buttons: buttons,
  }
}

function createCarouselIndicatorForSmall(count, classNamePrefix) {
  const fragment = document.createDocumentFragment()
  const buttons = []
  for (let i = 0; i < count; i++) {
    const li = document.createElement('li')
    const button = createDot([`${classNamePrefix}-dot`], `${i + 1}枚目までスクロール`)
    li.classList.add(`${classNamePrefix}-indicatorItem`)
    li.appendChild(button)
    fragment.appendChild(li)
    buttons.push(button)
  }

  return {
    list: fragment,
    buttons: buttons,
  }
}

function getText(index, count, lastPanelItemsCount) {
  const index1 = index + 1
  const panelFirst = 4 * index + 1
  if (index1 !== count || lastPanelItemsCount === 4) {
    return `${panelFirst}〜${panelFirst + 3}枚目を表示`
  }
  const lastItem = 4 * index + lastPanelItemsCount
  const lastItemBefore = lastItem - 3
  return `${lastItemBefore}〜${lastItem}枚目を表示`
}

function createDot(classNames, text) {
  const button = document.createElement('button')
  const span = document.createElement('span')
  const textNode = document.createTextNode(text)
  span.classList.add('sc21-u-visuallyHidden')
  span.appendChild(textNode)
  button.appendChild(span)
  button.setAttribute('type', 'button')
  button.classList.add(...classNames)
  return button
}

export { createCarouselIndicatorForWide, createCarouselIndicatorForSmall }
