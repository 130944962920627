class HomeSlider {
  constructor($el) {
    // read only props
    Object.defineProperty(this, '$el', { value: $el })
    Object.defineProperty(this, '$slideList', { value: $el.querySelectorAll('.sc21-homeSlider-slide') })
    Object.defineProperty(this, '$paginationItemList', {
      value: $el.querySelectorAll('.sc21-homeSlider-paginationItem'),
    })
    Object.defineProperty(this, '$paginationButtonList', {
      value: $el.querySelectorAll('.sc21-homeSlider-paginationButton'),
    })
    Object.defineProperty(this, '$prevButton', { value: $el.querySelector('.sc21-homeSlider-prevButton') })
    Object.defineProperty(this, '$nextButton', { value: $el.querySelector('.sc21-homeSlider-nextButton') })
    Object.defineProperty(this, '$stopButton', { value: $el.querySelector('.sc21-homeSlider-stopButton') })
    Object.defineProperty(this, 'itemLength', { value: this.$slideList.length })
    Object.defineProperty(this, 'duration', { value: 5000 })

    this.mql = window.matchMedia('screen and (max-width: 767px)')

    if (this.itemLength === 0) {
      return
    }

    if (this.itemLength === 1) {
      this.$slideList[0].classList.add('-current')
      return
    }

    this._timeoutId = 0
    this._running = false
    this._currentIndex = 0
    this._lastCurrentIndex = this.prevIndex

    this.$prevButton.addEventListener('click', this.toPrev.bind(this))
    this.$nextButton.addEventListener('click', this.toNext.bind(this))
    this.$stopButton.addEventListener('click', () => {
      this._running ? this.stop() : this.play()
    })

    this.$paginationButtonList.forEach(($paginationButton, i) => {
      $paginationButton.addEventListener('click', () => this.to(i))
    })

    try {
      this.mql.addEventListener('change', this.onChangeMql.bind(this))
    } catch (e) {
      this.mql.addListener(this.onChangeMql.bind(this))
    }
    this.onChangeMql(this.mql)

    this.play()
  }

  get prevIndex() {
    return this._currentIndex !== 0 ? this._currentIndex - 1 : this.itemLength - 1
  }

  get nextIndex() {
    return this.itemLength - 1 > this._currentIndex ? this._currentIndex + 1 : 0
  }

  get isLast() {
    return this._currentIndex === this.itemLength - 1
  }

  get itemWidth() {
    if (this.mql.matches) {
      // 5はアイテムについているマージン
      return `calc((100% - ${5 * this.itemLength}px) / ${this.itemLength})`
    }
    return ''
  }

  to(index) {
    if (this._currentIndex === index) return

    this._lastCurrentIndex = this._currentIndex
    this._currentIndex = index

    this._transition()
  }

  toNext() {
    this.to(this.nextIndex)
  }

  toPrev() {
    this.to(this.prevIndex)
  }

  stop() {
    if (!this._running) return

    this._running = false
    clearTimeout(this._timeoutId)
    this._timeoutId = 0
    this._updateClassNames()
  }

  play() {
    if (this._running) return

    this._running = true
    this._transition()
    this._updateClassNames()
  }

  _transition() {
    clearTimeout(this._timeoutId)

    for (let i = 0; i < this.itemLength; i++) {
      const $slide = this.$slideList[i]
      const $paginationItem = this.$paginationItemList[i]
      const $paginationButton = this.$paginationButtonList[i]

      // IE11 では、 remove() の引数は1つしか指定できない
      $slide.classList.remove('-current')
      $slide.classList.remove('-prev')
      $paginationItem.classList.remove('-current')
      $paginationItem.classList.remove('-prev')
      $paginationButton.removeAttribute('aria-current')
    }

    this.$slideList[this._currentIndex].classList.add('-current')
    this.$slideList[this._lastCurrentIndex].classList.add('-prev')

    this.$paginationItemList[this._currentIndex].classList.add('-current')
    this.$paginationItemList[this._currentIndex].style.width = this.itemWidth
    this.$paginationItemList[this._lastCurrentIndex].classList.add('-prev')
    this.$paginationItemList[this._lastCurrentIndex].style.width = ''

    this.$paginationButtonList[this._currentIndex].setAttribute('aria-current', 'true')

    if (this._running) {
      // 次回分のページ送りを予約
      this._timeoutId = window.setTimeout(() => this.toNext(), this.duration)
    }
  }

  _updateClassNames() {
    if (this._running) {
      this.$el.classList.add('-running')
      this.$stopButton.innerHTML = '一時停止'
    } else {
      this.$el.classList.remove('-running')
      this.$stopButton.innerHTML = '再生'
    }
  }

  onChangeMql(mql) {
    if (mql.matches) {
      this.$paginationItemList.forEach(item => {
        item.style.width = this._currentIndex === item ? this.itemWidth : ''
      })
    } else {
      this.$paginationItemList.forEach(item => {
        item.style.width = ''
      })
    }
  }
}

Array.prototype.forEach.call(document.querySelectorAll('.sc21-homeSlider'), $el => new HomeSlider($el))
