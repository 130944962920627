class TabUI {
  constructor(props) {
    const { root } = props
    this.elements = {
      root,
    }

    this.elements.tabs = root.querySelectorAll(props.tabSelector)
    this.elements.panels = root.querySelectorAll(props.panelSelector)

    this.tabsLength = this.elements.tabs.length

    if (this.tabsLength !== this.elements.panels.length) {
      return
    }

    this.currentSelectedTabIndex = -1
    this.tabsLastIndex = this.tabsLength - 1
    this.currentFocusedTabIndex = -1

    this.init()
  }

  getIndexOfTab(tabElement) {
    let matchedIndex = -1
    for (let i = 0; i < this.tabsLength; i++) {
      if (this.elements.tabs[i] === tabElement) {
        matchedIndex = i
        break
      }
    }
    return matchedIndex
  }

  onClickTab(event) {
    const clickedTab = event.currentTarget
    const clickedIndex = this.getIndexOfTab(clickedTab)
    const currentIndex = this.currentSelectedTabIndex
    if (clickedIndex === currentIndex) {
      return
    }
    this.changeTab(clickedIndex, currentIndex)
    this.currentSelectedTabIndex = clickedIndex
  }

  changeTab(nextIndex, currentIndex) {
    const nextTab = this.elements.tabs[nextIndex]
    const nextPanel = this.elements.panels[nextIndex]
    this.show(nextTab, nextPanel)
    if (currentIndex > -1) {
      const currentTab = this.elements.tabs[currentIndex]
      const currentPanel = this.elements.panels[currentIndex]
      this.hide(currentTab, currentPanel)
    }
  }

  show(tab, panel) {
    tab.setAttribute('aria-selected', 'true')
    tab.setAttribute('tabindex', '0')
    panel.setAttribute('aria-hidden', 'false')
  }

  hide(tab, panel) {
    tab.setAttribute('aria-selected', 'false')
    tab.setAttribute('tabindex', '-1')
    panel.setAttribute('aria-hidden', 'true')
  }

  onFocusTab(event) {
    const focusedTab = event.currentTarget
    this.currentFocusedTabIndex = this.getIndexOfTab(focusedTab)
  }

  onKeydownTab(event) {
    const key = event.key
    switch (key) {
      case 'ArrowRight':
      case 'Right': // for IE, Edge 16-, Firefox 36-
        this.focusNextTab()
        break
      case 'ArrowLeft':
      case 'Left': // for IE, Edge 16-, Firefox 36-
        this.focusPreviousTab()
        break
      case 'Home':
        this.focusTab(0)
        break
      case 'End':
        this.focusTab(this.tabsLastIndex)
        break
    }
  }

  focusTab(index) {
    this.elements.tabs[index].focus()
  }

  focusNextTab() {
    const currentIndex = this.currentFocusedTabIndex
    let nextIndex = currentIndex + 1
    if (nextIndex > this.tabsLastIndex) {
      nextIndex = 0
    }
    this.focusTab(nextIndex)
  }

  focusPreviousTab() {
    const currentIndex = this.currentFocusedTabIndex
    let nextIndex = currentIndex - 1
    if (nextIndex < 0) {
      nextIndex = this.tabsLastIndex
    }
    this.focusTab(nextIndex)
  }

  isSelectedTab(tab) {
    const ariaSelected = tab.getAttribute('aria-selected')
    return ariaSelected === 'true'
  }

  init() {
    this.onClickTab = this.onClickTab.bind(this)
    this.onFocusTab = this.onFocusTab.bind(this)
    this.onKeydownTab = this.onKeydownTab.bind(this)

    for (let i = 0; i < this.tabsLength; i++) {
      const tab = this.elements.tabs[i]
      const isSelected = this.isSelectedTab(tab)
      if (isSelected) {
        this.changeTab(i)
        this.currentSelectedTabIndex = i
      }
      tab.addEventListener('click', this.onClickTab, false)
      tab.addEventListener('focus', this.onFocusTab, false)
      tab.addEventListener('keydown', this.onKeydownTab, false)
    }
  }
}

export default TabUI
