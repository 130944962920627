class StickyCreditCardDetailApplicationHeader {
  constructor(props) {
    this.props = {
      ...props,
    }
    this.observeOptions = {
      threshold: [0.0, 1.0],
      rootMargin: '0px 0px -100% 0px',
    }
    this.observer = null
    this.init()
  }

  observeHandler(entries) {
    const { stickyHeader } = this.props
    const { top, bottom } = entries[0].boundingClientRect
    if (top < 0 && bottom > 0) {
      stickyHeader.classList.add('-sticky')
    } else {
      stickyHeader.classList.remove('-sticky')
    }
  }

  observe() {
    this.observer.observe(this.props.observeTarget)
  }

  disconnect() {
    this.observer.disconnect()
    this.props.stickyHeader.classList.remove('-sticky')
  }

  init() {
    this.observeHandler = this.observeHandler.bind(this)
    this.observer = new IntersectionObserver(this.observeHandler, this.observeOptions)
  }
}

export default StickyCreditCardDetailApplicationHeader
