import Accordion from '../classes/Accordion'

function initAccordion() {
  const accordionRoots = document.querySelectorAll('.sc21-accordion')
  accordionRoots.forEach(root => {
    const items = root.querySelectorAll('.sc21-accordion-item')

    if (!items || !items.length) {
      return
    }

    const accordion = new Accordion({
      root,
      items,
      triggerSelector: '.sc21-accordion-trigger',
      targetSelector: '.sc21-accordion-content',
    })

    // ページ読み込み時に location.hash とアコーディオン項目の ID が一致した場合、そのパネルを開いておく
    if (!location.hash) return

    const id = location.hash.replace(/^#/, '')
    const defaultPanelIndex = accordion.elements.targets.findIndex(targetElement => targetElement.id === id)
    if (defaultPanelIndex === -1) return

    accordion.expand(defaultPanelIndex)
  })
}

export default initAccordion
