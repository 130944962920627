class YouTubePlayer {
  constructor(props) {
    this.props = {
      ...props,
    }

    const { root } = this.props

    this.elements = {
      root,
      button: root.querySelector(this.props.buttonSelector),
      player: root.querySelector(this.props.playerSelector),
      transitionTarget: root.querySelector(this.props.transitionTargetSelector),
    }

    this.player = null
    this.showPlayer = false
    this.init()
  }

  onClickButton() {
    this.elements.root.classList.add('-showPlayer')
  }

  onTransitionend(event) {
    if (!(event.propertyName === this.props.transitionProperty && event.target === this.elements.transitionTarget)) {
      return
    }
    if (this.showPlayer) {
      this.showPlayer = false
    } else {
      this.player.playVideo()
      this.showPlayer = true
    }
  }

  onVideEnded() {
    this.player.stopVideo()
    this.elements.root.classList.remove('-showPlayer')
  }

  onPlayerReady() {
    // なんか処理することあったら
  }

  onPlayerStateChange(event) {
    if (event.data === YT.PlayerState.ENDED) {
      this.onVideEnded()
    }
  }

  initYtPlayer() {
    this.player = new YT.Player(this.elements.player, {
      width: '896',
      height: '504',
      videoId: this.props.videoId,
      playerVars: {
        enablejsapi: 1,
        rel: 0,
        showinfo: 0,
        ecver: 2,
      },
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
      },
    })
  }

  init() {
    this.onPlayerReady = this.onPlayerReady.bind(this)
    this.onPlayerStateChange = this.onPlayerStateChange.bind(this)
    this.onClickButton = this.onClickButton.bind(this)
    this.onTransitionend = this.onTransitionend.bind(this)
    this.elements.button.addEventListener('click', this.onClickButton)
    this.elements.root.addEventListener('transitionend', this.onTransitionend)
    this.initYtPlayer()
  }
}

export default YouTubePlayer
