const setCustomPropertyVh = () => {
  window.addEventListener('resize', () => setVh())
  setVh()
}

const setVh = () => {
  const rootStyle = document.documentElement.style
  const vh = window.innerHeight / 100
  rootStyle.setProperty('--vh', `${vh}px`)
}

export default setCustomPropertyVh
