import GlobalFooter from '../classes/GlobalFooter'

function initGlobalFooter() {
  const root = document.querySelector('.sc21-GlobalFooter')
  if (!root) {
    return
  }
  const toggler = root.querySelectorAll('.sc21-GlobalFooter-sitemapToggler')
  const content = root.querySelectorAll('.sc21-GlobalFooter-sitemapContent')

  // 実行
  new GlobalFooter({
    root,
    toggler,
    content,
  })
}

export default initGlobalFooter
